import {Alert, AlertTitle, Box, Button, Stack, useTheme} from "@mui/material";
import {alpha} from "@mui/system/colorManipulator";
import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, View, ViewHeader} from "@variocube/app-ui";
import React, {Dispatch, SetStateAction} from "react";
import {useDropzone} from "react-dropzone";
import {Link as RouterLink} from "react-router-dom";
import {useFileApi} from "../../../../api/files";
import {useLocalization} from "../../../../i18n";
import {isValidAttachmentType, isValidDocumentType} from "../../../../utils/isValidFileType";
import {AttachmentFile, InvoiceFile} from "../types";
import {InvoiceCard} from "./invoice-card";

interface UploadInvoiceViewProps {
	invoices: InvoiceFile[];
	setInvoices: Dispatch<SetStateAction<InvoiceFile[]>>;
	onNext: () => any;
}

export function UploadInvoiceView({invoices, setInvoices, onNext}: Readonly<UploadInvoiceViewProps>) {
	const {t} = useLocalization();
	const {uploadFile} = useFileApi();

	function updateInvoice(file: File, update: Partial<InvoiceFile>) {
		setInvoices(prev => prev.map(f => f.file === file ? validateFileTypes({...f, ...update}) : f));
	}

	function applyToOthers(invoice: InvoiceFile) {
		setInvoices(prev =>
			prev.map(f =>
				f.file == invoice.file ? f : validateFileTypes({
					...f,
					contactOebb: invoice.contactOebb,
					orderNumbers: invoice.orderNumbers,
					profitCenter: invoice.profitCenter,
					inbox: invoice.inbox,
					inboxId: invoice.inboxId,
					additionalInfo: invoice.additionalInfo,
					attachments: [...invoice.attachments],
				})
			)
		);
	}

	function updateAttachment(invoice: InvoiceFile, attachment: File, update: Partial<AttachmentFile>) {
		setInvoices(prev =>
			prev.map(f =>
				f.file === invoice.file
					? validateFileTypes({
						...f,
						attachments: f.attachments.map(a => a.file === attachment ? {...a, ...update} : a),
					})
					: f
			)
		);
	}

	function addInvoices(files: ReadonlyArray<File>) {
		setInvoices(prev => [
			...prev,
			...files.map(file => ({
				file,
				orderNumbers: [],
				attachments: [],
				contactOebb: "",
				additionalInfo: "",
			})),
		]);

		for (const file of files) {
			uploadFile(file, {
				onProgress: progress => updateInvoice(file, {progress}),
				onSuccess: tempFile => updateInvoice(file, tempFile),
				onError: error => updateInvoice(file, {error}),
			});
		}
	}

	function addAttachments(invoice: InvoiceFile, files: ReadonlyArray<File>) {
		updateInvoice(invoice.file, {
			attachments: [...invoice.attachments, ...files.map(file => ({file}))],
		});
		for (const attachment of files) {
			uploadFile(attachment, {
				onProgress: progress => updateAttachment(invoice, attachment, {progress}),
				onSuccess: tempFile => updateAttachment(invoice, attachment, tempFile),
				onError: error => updateAttachment(invoice, attachment, {error}),
			});
		}
	}

	function handleDelete({file}: InvoiceFile) {
		setInvoices(prev => prev.filter(invoice => invoice.file !== file));
	}

	function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
		onNext();
	}

	const {getRootProps, getInputProps, isDragActive, open} = useDropzone({
		useFsAccessApi: false,
		multiple: true,
		onDrop: addInvoices,
		noClick: true,
	});

	const theme = useTheme();

	return (
		<Box
			{...getRootProps()}
			flex={1}
			border={`1px solid ${isDragActive ? theme.palette.secondary.main : "transparent"}`}
			bgcolor={isDragActive
				? alpha(theme.palette.secondary.main, theme.palette.action.selectedOpacity)
				: undefined}
		>
			<View>
				<Breadcrumbs>
					<BreadcrumbLink component={RouterLink} to="/invoices">{t("invoices.plural")}</BreadcrumbLink>
					<BreadcrumbItem>{t("invoices.new.creditor.title")}</BreadcrumbItem>
				</Breadcrumbs>
				<ViewHeader
					title={t("invoices.new.creditor.title")}
					subTitle={t("invoices.new.creditor.upload")}
				/>
				<Alert severity="info">
					<AlertTitle>{t("invoices.new.creditor.info.title")}</AlertTitle>
					<ol>
						<li>
							{t("invoices.new.creditor.info.step1")}
						</li>
						<li>
							{t("invoices.new.creditor.info.step2")}
						</li>
						<li>
							{t("invoices.new.creditor.info.step3")}
						</li>
						<li>
							{t("invoices.new.creditor.info.step4")}
						</li>
					</ol>
				</Alert>
				<form onSubmit={handleSubmit}>
					<input {...getInputProps()} />
					<Stack spacing={2}>
						{invoices.map(invoice => (
							<InvoiceCard
								key={invoice.file.name}
								invoice={invoice}
								onDelete={() => handleDelete(invoice)}
								onChangeInboxId={(inboxId, inbox) => updateInvoice(invoice.file, {inboxId, inbox})}
								onChangeContactOebb={contactOebb => updateInvoice(invoice.file, {contactOebb})}
								onChangeOrderNumbers={orderNumbers => updateInvoice(invoice.file, {orderNumbers})}
								onChangeProfitCenter={profitCenter => updateInvoice(invoice.file, {profitCenter})}
								onChangeAdditionalInfo={additionalInfo => updateInvoice(invoice.file, {additionalInfo})}
								onApplyToOther={() => applyToOthers(invoice)}
								onAddAttachments={files => addAttachments(invoice, files)}
								onDeleteAttachment={attachment =>
									updateInvoice(invoice.file, {
										attachments: invoice.attachments.filter(a => a.file !== attachment),
									})}
							/>
						))}
						{invoices.length == 0 && (
							<Button
								color="primary"
								variant="outlined"
								onClick={open}
								size="large"
								sx={{height: 240}}
							>
								{t("invoices.new.creditor.addInvoices")}
							</Button>
						)}
						<Alert severity="info">
							{t("invoices.new.creditor.nextPage")}
						</Alert>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							disabled={invoices.length === 0 || invoices.some(invoice =>
								!invoice.checksum
								|| !invoice.supported
								|| !invoice.attachments.every(attachment => attachment.supported)
							)}
						>
							{t("actions.next")}
						</Button>
					</Stack>
				</form>
			</View>
		</Box>
	);
}

export function validateFileTypes(invoice: InvoiceFile) {
	return {
		...invoice,
		supported: isValidDocumentType(invoice.inbox ?? undefined, invoice.file),
		attachments: invoice.attachments.map(attachment => ({
			...attachment,
			supported: isValidAttachmentType(invoice.inbox ?? undefined, attachment.file),
		})),
	};
}
