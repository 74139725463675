import {useFlag} from "@variocube/app-ui";
import React, {useState} from "react";
import {Invoice, useInvoicesApi} from "../../../../api";
import {useReloadInvoiceCount} from "../../../../context/auth";
import {InvoiceFile} from "../types";
import {UploadInvoiceSummaryView} from "./upload-invoice-summary-view";
import {UploadInvoiceView} from "./upload-invoice-view";
import {UploadSuccessView} from "./upload-success-view";

export function CreditorInvoiceCreate() {
	const [invoices, setInvoices] = useState<InvoiceFile[]>([]);
	const [summary, setSummary, clearSummary] = useFlag(false);
	const [created, setCreated] = useState<Invoice[]>([]);
	const {submitCreditorInvoices} = useInvoicesApi();
	const reloadInvoiceCount = useReloadInvoiceCount();

	async function submitInvoices() {
		const submissions = invoices.map(invoice => ({
			filename: invoice.file.name,
			checksum: invoice.checksum!,
			contentType: invoice.file.type,
			contactOebb: invoice.contactOebb,
			inboxId: invoice.inboxId!,
			additionalInformation: invoice.additionalInfo,
			orderNumbers: invoice.orderNumbers,
			profitCenter: invoice.profitCenter,
			attachments: invoice.attachments.map(attachment => ({
				filename: attachment.file.name,
				checksum: attachment.checksum!,
				contentType: attachment.file.type,
			})),
		}));
		const result = await submitCreditorInvoices(submissions);
		await reloadInvoiceCount();
		setCreated(result);
	}

	if (created.length > 0) {
		return <UploadSuccessView created={created} />;
	}
	if (!summary) {
		return <UploadInvoiceView invoices={invoices} setInvoices={setInvoices} onNext={setSummary} />;
	}
	else {
		return <UploadInvoiceSummaryView invoices={invoices} onBack={clearSummary} onSubmit={submitInvoices} />;
	}
}
